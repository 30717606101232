<template>
  <b-modal
    id="game-deposit-modal"
    ref="game-deposit-modal"
    hide-footer
    no-close-on-backdrop
    title="เติมเครดิตเกม"
    @show="setData"
    class="position-relative"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-sm-6">
          <div class="mb-2">
            <span>จากบัญชี</span>
            <div class="text-center alert alert-primary p-1"><h2 class="m-0">{{ agUsername }}</h2></div>
          </div>
          <div class="mb-2">
            <span>ยอดคงเหลือ</span>
            <div class="text-center alert alert-primary p-1"><h4 class="m-0 text-right">{{ balance | amountFormat(2, '0.00') }}</h4></div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb-2">
            <span>เข้าบัญชี</span>
            <div class="text-center alert alert-success p-1"><h2 class="m-0">{{ mbUsername }}</h2></div>
          </div>
          <div class="mb-2">
            <span>ยอดคงเหลือ</span>
            <div class="text-center alert alert-success p-1"><h4 class="m-0 text-right">{{ mbBalance | amountFormat(2, '0.00') }}</h4></div>
          </div>
        </div>
      </div>

      <b-form-group
        label="จำนวน"
      >
        <b-form-input
          v-model="input.amount"
          type="number"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รายละเอียด"
      >
        <b-form-input
          v-model="input.note"
          type="text"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="success" :disabled="isProcess">{{isProcess ? 'กำลังเติมเครดิต...' : 'เติมเครดิต'}}</b-button>
      </div>
    </form>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import cryptoRandomString from 'crypto-random-string'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'GameDepositModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    balance: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isProcess: false,
      isLoading: false,
      needReload: false,
      account: null,
      input: {
        uniqueCode: null,
        amount: null,
        note: null
      }
    }
  },
  computed: {
    agUsername() {
      return this.$store.state.userProfile.username
    },
    mbUsername() {
      return this.data?.user?.username ?? ''
    },
    mbBalance() {
      return this.data?.balance ?? 0
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['game-deposit-modal'].show()
    },
    hideModal() {
      this.$refs['game-deposit-modal'].hide()
    },
    setData() {
      this.needReload = false
      this.isProcess = false
      this.isLoading = false
      this.input = {
        uniqueCode: cryptoRandomString({length: 12, type: 'alphanumeric'}),
        amount: null,
        note: null
      }
    },
    handleSubmit() {

      if(this.input.amount <= 0 || this.balance < this.input.amount) {
        return Swal.fire({
          text: 'จำนวนไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.isProcess = true
      this.isLoading = true
      GameService.deposit(this.data.accountId, this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เติมเครดิตเสร็จเรียบร้อย',
          })
        }else{
          throw response
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'เติมเครดิตไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
        this.isLoading = false
        this.input.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === 'game-deposit-modal')
        this.$emit('close', this.needReload)
    })
  }
}
</script>
