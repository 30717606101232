<template>
  <b-modal
    id="game-credit-modal"
    ref="game-credit-modal"
    hide-footer
    no-close-on-backdrop
    :title="title"
    size="xl"
  >
    <GameCreditBox :accountId="accountId" @data="setData" />
  </b-modal>
</template>
<script>
import GameCreditBox from './GameCreditBox'

export default {
  name: 'GameCreditModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: null
    }
  },
  components: {
    GameCreditBox
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    username() {
      return this.data?.account?.username
    },
    fullname() {
      return this.data?.account?.fullName
    },
    title() {
      return `ประวัติเครดิต: ${this.username} (${this.fullname})`
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['game-credit-modal'].show()
    },
    hideModal() {
      this.$refs['game-credit-modal'].hide()
    },
    setData(data) {
      this.data = data
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === 'game-credit-modal')
        this.$emit('close', false)
    })
  }
}
</script>
